@media print {

  header,
  h1,
  button {
    display: none !important;
  }

  main {
    padding: 0 !important;
  }

}

.screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 297mm;
  justify-content: center;
  position: relative;
  width: 210mm;
  break-before: always;

  .top {
    background-size: cover;
    box-shadow: inset 0px 0px 50px black;
    height: 50%;
    width: 100%;
  }

  .mid {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1em;
    box-shadow: 0px 0px 10px black;
    color: white;
    font-family: 'Scorchedearth';
    font-size: 2.5em;
    letter-spacing: 0.4em;
    padding: 0.4em 0.8em;
    position: absolute;
    text-shadow: 2px 2px 2px black;
    top: 50%;
    transform: translateY(-50%);
  }

  .bottom {
    background-size: cover;
    box-shadow: inset 0px 0px 50px black;
    height: 50%;
    width: 100%;
  }

  &.page1 {
    .top {
      background-image: url('../../public/images/screen/forest.webp');
    }

    .bottom {
      background-image: url('../../public/images/screen/ruins.webp');
    }
  }

  &.page2 {
    .top {
      background-image: url('../../public/images/screen/town.webp');
    }

    .bottom {
      background-image: url('../../public/images/screen/soldier.webp');
    }
  }

  &.page3 {
    .top {
      background-image: url('../../public/images/screen/desert.webp');
    }

    .bottom {
      background-image: url('../../public/images/screen/city.webp');
    }
  }
}
