.armor-data-box {
  display: flex;
  flex-direction: column;

  .armor-data {
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    margin: 5px 0px;
  }

  hr {
    width: 100%;
  }

  &.bordered {
    border-bottom: dashed 1px DarkSlateGray;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
