.button {
  bottom: 75px;
  position: fixed !important;
  right: 15px;
  transition: 0.3s ease !important;
}

.visible {
  opacity: 0.8;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  z-index: -500;
}
