.pageCenter {
  display: flex;
  height: 100%;
}

// .sideLeft {
//   a {
//     color: DarkSlateGray;
//   }
// }

// .sideRight {
//   a {
//     color: DarkSlateGray;
//   }
// }

// .text {
//   margin: auto;
//   padding: 3px 0px;
//   white-space: nowrap;
// }
