html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
  margin: 0;

  font-family: 'Calibri', 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

#root {
  min-height: 100vh;
}

.App {
  color: DarkSlateGray;
  min-height: 100vh;
  text-align: center;
}

main {
  display: block;
  min-height: calc(100vh - 64px);
  padding-bottom: 76px;
}
