@keyframes irradiatedPulse {
  0% {
    text-shadow: 0px 0px 1px white;
  }

  40% {
    text-shadow: 0px 0px 1px white;
  }

  45% {
    text-shadow: -2px -2px 2px #AFE1AF;
  }

  48% {
    text-shadow: 0px 1px 2px #ECFFDC;
  }

  50% {
    text-shadow: 1px 0px 3px #90EE90;
  }

  52% {
    text-shadow: -2px 2px 2px #9FE2BF;
  }

  55% {
    text-shadow: 1px 0px 3px #C1E1C1;
  }

  60% {
    text-shadow: 0px 0px 1px white;
  }

  100% {
    text-shadow: 0px 0px 1px white;
  }
}

@keyframes quote-movingShadow {
  0% {
    box-shadow: -10px 0 15px -10px DarkSlateGray;
  }

  25% {
    box-shadow: -10px 0 15px -10px DarkSlateGray;
  }

  50% {
    box-shadow: 5px 0px 20px -10px DarkSlateGray;
  }

  75% {
    box-shadow: -10px 0 15px -10px DarkSlateGray;
  }

  100% {
    box-shadow: -10px 0 15px -10px DarkSlateGray;
  }
}

@keyframes image-pulsingShadow {
  0% {
    filter: drop-shadow(0px 0px 3px DarkSlateGray);
  }

  50% {
    filter: drop-shadow(0px 0px 6px DarkSlateGray);
  }

  100% {
    filter: drop-shadow(0px 0px 3px DarkSlateGray);
  }
}
