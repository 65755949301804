.afterworld-card.gift {
  .afterworld-container {
    .body {
      min-height: 30px;
    }
  }
}

.giftBody:not(:first-child) {
  border-top: dotted 1px lightgray;
  margin-top: 1px;
}
