@import 'styles/components/selectable';

.data {
  @extend .selectable;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: left;
  white-space: break-spaces;
}
