.box {
  border-radius: 2px;
  box-shadow: 0px 0px 2px black;
  display: inline-block;
  margin: 0 1px;
  padding: 2px 4px 1px 6px;
}

.darkgreen {
  background-color: DarkSlateGray;
  color: white;
}

.lightgreen {
  background-color: rgba(96, 158, 158, 0.5);
  color: black;
}

.darkgray {
  background-color: SlateGray;
  color: white;
}

.lightgray {
  background-color: lightgray;
  color: black;
}
