.afterworld-table {

  &.style-classic {
    box-shadow: 0px 0px 4px DarkSlateGray;
    margin: 16px 0px;
  }

  &.responsive {
    overflow-x: auto;

    table {
      min-width: 650px;
    }
  }

  table {
    border-collapse: collapse;
    overflow: hidden;
    table-layout: fixed;
    text-align: center;
    width: 100%;

    thead {
      background-color: #E0E0E0;
      border-bottom: solid 1px black;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: dotted 1px DarkSlateGray;
        }

        &:nth-of-type(even) {
          background-color: #F0F0F0;
        }

        &:nth-of-type(odd) {
          background-color: #FAFAFA;
        }

        td {
          padding: 6px 12px;

          &:not(:last-child) {
            border-right: solid 1px DarkSlateGray;
          }
        }
      }
    }

    tr {

      td,
      th {
        padding: 6px 12px;

        &:not(:last-child) {
          border-right: solid 1px DarkSlateGray;
        }
      }
    }
  }
}
