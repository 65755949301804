.pin {
  background: DarkSlateGray;
  border-radius: 2px;
  bottom: auto;
  box-shadow: 0 0 1px 1px LightSlateGray;
  color: Gainsboro;
  cursor: help;
  font-size: 0.9em;
  padding: 3px 6px;

  &.pin-left,
  &.pin-right {
    background: linear-gradient(45deg,
        #999 5%,
        #fff 10%,
        #ccc 30%,
        #ddd 50%,
        #ccc 70%,
        #fff 80%,
        #999 95%);
    box-shadow: inset 0px 0px 2px DarkSlateGray;
    color: DarkSlateGray;
    font-weight: bold;
    position: absolute;
    text-shadow: 0px 0px 1px white;
    top: calc(50% - 8px);
  }

  &.pin-left {
    left: 6px;
  }

  &.pin-right {
    right: 6px;
  }
}
