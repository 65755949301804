.container {
  text-shadow: 0px 0px 1px LightSlateGray;
}

.intro {
  font-family: DemonSker;
  font-size: 2em;
  font-weight: 400;
  /* height: 20vh; */
  letter-spacing: 0.05em;
  padding-top: 20px;
}

.text {
  font-family: CfWhiteTrashRegular;
  font-size: 1.4em;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-top: 40px;
}

.dropShadow {
  filter: drop-shadow(0px 0px 1px LightSlateGray)
}
