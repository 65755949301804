.armor-data-area {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  letter-spacing: 2px;
  min-width: 20%;
  padding: 2px 4px;
  text-align: center;

  .armor-data-area-value {
    font-size: 1.1em;
  }

  &.full {
    box-shadow: 0px 0px 2px black;

    .armor-data-area-name {
      border-bottom: solid 1px black;
    }
  }

  &.empty {
    box-shadow: 0px 0px 1px DarkSlateGrey;
    text-decoration: line-through
  }
}
