.App {
  header.appMenu {
    background: linear-gradient(LightSlateGray, DarkSlateGray);

    .MuiButtonBase-root {
      color: wheat;
      flex-direction: column-reverse;
      font-family: 'Scorchedearth';
      font-size: 0.5em;
      letter-spacing: 0.2em;

      .MuiButton-endIcon {
        margin: 0;
      }
    }
  }
}

.header__menu {
  .MuiMenu-paper {
    bottom: 75px !important;
    left: 15px !important;
    right: 15px !important;
    top: auto !important;

    .MuiList-root.MuiMenu-list {
      .MuiButtonBase-root.MuiMenuItem-root {
        color: DarkSlateGray;
        font-family: 'Scorchedearth';
        letter-spacing: 0.2em;

        svg {
          margin-right: auto;
        }
      }
    }
  }
}
