.titleBox {
  color: DarkSlateGray;
  display: flex;
  font-family: 'Scorchedearth';
  font-size: 20px;
  letter-spacing: 2px;
  overflow: hidden;
  padding: 8px 0px;
  position: relative;
  text-align: center;
  text-shadow: 0px 0px 2px LightSlateGray;
}

.sideLeft {
  color: DarkSlateGray;

  a {
    color: DarkSlateGray;
  }
}

.sideRight {
  color: DarkSlateGray;

  a {
    color: DarkSlateGray;
  }
}

.text {
  margin: auto;
  padding: 3px 0px;
  white-space: nowrap;
}
